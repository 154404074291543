.row:before, .row:after {
	display: none !important;
}

body{
	overflow-x: hidden;

	&.page-template-template-front{ 
		position: relative;

		&:after{
			content:'';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index:-1;
			opacity: 0;

			background-image: url('images/background-home.png');
			background-size: 220%;
			background-repeat: no-repeat;
			background-position: center top;
			transform: scale(1.15);
			
			@include media-breakpoint-up(lg){
				background-size: contain;
				background-repeat: no-repeat;
			}			

            animation-name: background-reveal-in;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            animation-duration: 4.5s;
		}
	}
}

@media (min-width: 1650px){
	.container{
		max-width: 1550px;
	}
}

@keyframes background-reveal-in{
    from {opacity: 0; }
    to {opacity: 1; transform: scale(1);}  
}
