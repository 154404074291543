/**
 * Grid item
**/
.grid-item{
    &:hover{
        img{
            transform: scale(1.03);
        }

        h3{
            color: $blue;
        }
    }

    .image-wrapper{
        overflow: hidden;

        img{
            width: 100%;
            transition: transform 2.5s cubic-bezier(0, 0.55, 0.45, 1);
        }
    }

    h3{
        font-family: $sans-serif;
        font-size: 16px;
        letter-spacing: 3.25px;
        line-height: 26px;
        text-transform: uppercase;
        margin-top: 10px;
        margin-bottom: 75px;
        transition: all .5s cubic-bezier(0, 0.55, 0.45, 1);
    }

    a{
        text-decoration: none;
    }

    a h3{
        color: $black;
    }


    &--news{
        h3{
            letter-spacing: .25px;
        }
    }
}

.news-grid-overview{
    .grid-item--news{
        margin-bottom: 60px;
    }
}