/**
 * Brands
**/


.brand{
    padding: 15px;

    a{
        height: 100%;
        display: block;
    }

    &__color-wrap{
        display: flex;
        align-content: center;
        height: 100%;
        justify-content: center;
        padding: 45px 30px;
    }

    img{
        mix-blend-mode: multiply;
        max-height: 60px;
        max-width: 170px;        
    }
}