/**
 * Main navigation
**/
.nav-wrapper{
    border-bottom: 1px solid rgba($black, .05);	
}

.navbar-toggler{
	position: relative;
	z-index: 9999999;
}

.navbar{
	.nav-link{
		color: $black;
		font-size: 13px;
		letter-spacing: .25px;
		text-transform: uppercase;
		margin-right: 15px;
		padding-top: 11.5px;
		padding-bottom: 11.5px;
		position: relative;

		&:after{
			content:'';
			position: absolute;
			background-color: transparent;
			width: 0%;
			height: 0.75px;
			left: 9px;
			bottom: -22px;
			transition: all .3s ease;
		}

		&:hover{
			&:after{
				background-color: $orange;
				width: calc(100% - 18px);
			}
		}
	}

	.current_page_item{
		.nav-link{
			&:after{
				background-color: $orange;
			}
		}
	}	

	.nav-btn a{
		@extend .btn;
		@extend .btn--black-transparent;

		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 30px !important;
		padding-right: 30px !important;
		
		@include media-breakpoint-up(lg){
			margin-right: 0px;
		}

		&:after{
			display: none;
		}
	}
}



/**
 * Mobile only
 **/
 .navbar-toggler{
	border: 0;
    top: 17px;
}


@include media-breakpoint-down(lg) {
	#menu-entries{
		display: none !important;
		transition: all .3s cubic-bezier(0, 0.55, 0.45, 1);
		opacity: 0;

		.nav-link{
			transform: translateY(-15px);
			transition: all .45s cubic-bezier(0, 0.55, 0.45, 1);
		}

		&.show{
			display: flex !important;
			height: 100vh;
			z-index: 9999;
			top: 0;
			left: -12px;
			width: calc(100% + 25px);
			background-color: $black;
			padding-left: 0;
			margin-left: 0;
			position: fixed;
			opacity: 1;

			.nav-link{
				color: $white;
				font-family: $serif;
				text-align: center;
				font-size: 22px;
				line-height: 36px;
				text-transform: inherit;
				transform: translateY(0px);
				

				&:hover{
					&:after,
					&:before{
						display: none;
					}
				}
			}

			.nav-btn{
				text-align: center;
			}

		}

		ul{
			width: 100%;
		}
	}

	.navbar{
		display: block !important;
	}

	.navbar-toggler{
		position: fixed;
		right: 27px;

		.navbar-toggler-icon{
			background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
			border-color: $black;
		}

		&.collapsed{
			position: absolute;
			right: 15px;

			.navbar-toggler-icon{
				background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
				border-color: $white;
			}
		}
	}
}


#menu-entries{
	flex-direction: row-reverse;
	

	.dropdown.child{
		position: relative !important;
		.dropdown-sub{
			position: absolute !important;
			right: 0;
		}
	}
}
