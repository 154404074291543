/**
 * Services
**/
.service-item{
    img{
        width: 100%;
    }

    &__content{
        display: flex;
        align-items: center;        
    }

    &:nth-of-type(odd){
        @include media-breakpoint-up(lg){
            .service-item__content{
                position: relative;
                left: -75px;
            }
        }

        @include media-breakpoint-down(md){
            .service-item__content-wrap{
                border-radius: 0px 0px 25px 25px;
            }

            .service-item__image{
                border-radius: 25px 25px 0px 0px;
            }
        }
    }

    &:nth-of-type(even){
        @include media-breakpoint-up(lg){
            .service-item__content{
                position: relative;
                left: 75px;
            }
        }

        @include media-breakpoint-down(md){
            .service-item__content-wrap{
                border-radius: 25px 25px 0px 0px;
            }

            .service-item__image{
                border-radius: 0px 0px 25px 25px;
            }
        }        
    }

    &__content-wrap{
        padding: 45px 35px;

        @include media-breakpoint-up(lg){
            padding: 45px 55px;
        }

        @include media-breakpoint-down(sm){
            .btn{
                padding: 10px 10px;
                margin-top: 10px;
            }
        }
    }
}