/**
 * Contact
**/

.google-map{
    height: 400px;
}

.contact-page{
    &__info{
        position: relative;
        z-index: 1;
        padding: 45px 55px;
        margin-top: 60px;

        @include media-breakpoint-up(md){
            margin-top: 0;
            margin-bottom: -135px;
        }
    }

    h4{
        margin-top: 40px;
    }

    a{
        color: $black;
        text-decoration: none;
        transition: all .3s cubic-bezier(0, 0.55, 0.45, 1);

        &:hover{
            color: $orange;
        }
    }

    footer{
        border-top: 0;

        .social-media{
            margin-top: 60px;
        }
    }

    form{
        label{
            color: $black;
            font-family: $sans-serif;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 26px;
            text-transform: uppercase;            
        }

        label,
        span{
            width: 100%;
        }

        input,
        textarea{
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid #a4a4a4;
            box-shadow: none;
            color: #666666;
            display:block;
            width: 100%;
            margin-bottom: 35px;

            transition: all .4s ease;

            &:focus{
                outline: 0;
                border-bottom: 1px solid $black;
            }
        }

        input[type="submit"]{
            @extend .btn;
            @extend .btn--black;
            @extend .btn--line-before;
            padding: 10px 40px !important;
            width: auto;
        }       
    }


    .wpcf7-not-valid-tip{
        color: $orange;
        font-size: 14px;
        margin-bottom: 40px;
        margin-top: -30px;        
    } 

    .wpcf7-response-output{
        border-color: $orange !important;
    }
}