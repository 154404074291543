// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/dropdown";
/* Niet alle bootstrap elementen zijn momenteel toegevoegd: alle zijn te vinden in /node_modules/bootstrap/scss/ */

// 5. Add additional custom code here

// Global
@import "lib/global/variables";
@import "lib/global/typography";

// Modules
@import "lib/modules/mixins";
@import "lib/modules/fonts";
@import "lib/modules/grid-item";

// Partials
@import "lib/partials/body";
@import "lib/partials/wordpress";
@import "lib/partials/utils";
@import "lib/partials/wrapper";
@import "lib/partials/header";
@import "lib/partials/nav";
@import "lib/partials/sidebar";
@import "lib/partials/footer";
@import "lib/partials/contentblocks";

// Templates
@import "lib/templates/front";
@import "lib/templates/sub";
@import "lib/templates/services";
@import "lib/templates/brands";
@import "lib/templates/contact";
@import "lib/templates/single";