/**
 * Single
**/

/**
 * Body content
**/
.article-content{
    h1,
    h2{
        max-width: 940px;
    }

    h2{
        margin-top: 50px;
    }

    h3{
        margin-top: 40px;
    }

    .page-intro h2{
        margin-top: 0;
    }
}

.article-body{

    @include media-breakpoint-up(lg){
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        ol,
        table,
        p{
            margin-left: 16.66666667%;
            margin-right: 16.66666667%;        
        }

        h3{
            font-size: 32px;
        }
    
    }

    h2{
        margin-top: 60px;
        margin-bottom: 20px;        
    }

    h3,
    h4,
    .wp-block-gallery{
        margin-top: 60px;
    }

    h3:first-of-type{
        //margin-top: 0;
    }

    ul,
    ol{
        font-size: 15px;
        line-height: 26px;
    }

    a:not(.btn){
        color: $blue;
        text-decoration-thickness: 1.5px;
        text-underline-offset: 3px;
        text-decoration-color: $blue-light;
        transition: all .2s ease;

        &:hover{
            text-decoration-color: $blue;
        }
    }

    .btn{
        margin-top: 25px;
    }

    .wp-block-image{
        border-radius: 8px;
        overflow: hidden;
    }

    blockquote{
        padding: 30px 30px;
        background-color: $blue-light;
        margin-top: 50px;
        margin-bottom: 45px;
        border-radius: 8px;
        font-family: $serif;
        font-weight: 400;  
        
        p{
            font-size: 22px;
            line-height: 32px;        
        }

        em{
            font-size: 15px;
            line-height: 22px;
            margin-top: 10px;
            display: block;            
        }

        @include media-breakpoint-up(lg){
            margin-left: 75px;
            margin-right: 75px;
            padding: 75px 45px;
            
            p{
                font-size: 28px;
                line-height: 38px;                 
            }
        }
    }
}