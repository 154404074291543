/**
 * Sub
**/

.page-intro{
    h1{ 
        span{
            color: $orange;
            display: block;
            font-family: $sans-serif;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 6px;
            line-height: 30px;
            text-transform: uppercase;
            margin-left: 70px;       
            position: relative;

            &:before{
                content: '';
                position: absolute; 
                left: -70px;
                height: 1px;
                width: 50px;
                top: 15px;
                background-color: $orange;
            }
        }

        @include media-breakpoint-down(sm){
            font-size: 42px;

            span{
                margin-left: 40px;

                &:before{
                    left: -40px;
                    width: 25px;
                }
            }
        }
    }

    h2{
        font-family: $sans-serif;
        font-size: 22px;
        color: $gray;
        letter-spacing: 0;
        line-height: 30px;
        max-width: 925px; 
        margin-bottom: 60px;
    }

    .btn-wrapper{
        margin-top: 75px;
    }
}