/**
 * Header
**/

.header-title{
    &--home{
        max-width: 750px;
        margin: 0 auto;      
        
        h1{
            margin-bottom: 25px;

            @include media-breakpoint-up(sm){
                margin-bottom: 45px;
            }

            @include media-breakpoint-up(md){
                font-size: 50px;
            }

            @include media-breakpoint-up(lg){
                font-size: 68px;
            }

            opacity: 0;
            transform: translateY(10px);      
            animation-name: reveal-in;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            animation-duration: 1s;
            animation-delay: 1s;                 
        }

        p{
            opacity: 0;
            transform: translateY(10px);      
            animation-name: reveal-in;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            animation-duration: 1.25s;
            animation-delay: 1.25s;                
        }
    }
}

.header-image{
    img{
        width: 100%;
    }

    @include media-breakpoint-down(sm){
        .container{
            padding-left: 0;
            padding-right: 0;
        }

        .border-37{
            border-radius: 0;
        }
    }

    &--sub{
        @include media-breakpoint-down(sm){
            padding-top: 0;
        }
    }
}

@keyframes reveal-in{
    from {opacity: 0;}
    to {opacity: 1; transform: translateY(0px);}  
}
