
/**
 * Typography
**/

*{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    color: $black;
    font-family: $sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $serif;
    font-weight: 400;
}

h1{
    font-weight: 400;
    letter-spacing: .6px;

    @include media-breakpoint-up(lg){
        font-size: 68px;
        line-height: 78px;
    }
}

h2,
h3{
    font-weight: 400;
    letter-spacing: .55px;

    @include media-breakpoint-up(lg){
        font-size: 38px;
        line-height: 48px;         
    }
}

p{
    font-size: 15px;
    line-height: 26px;
}


/**
 * Typo class
**/
.text-center{
    text-align: center !important;
}

.text-right{
    text-align: right;
}

.font-bold{
    font-weight: 700; 
}

/**
 * Buttons
**/

.btn{
    border-radius: 50px;
    display: inline-block;
    font-size: 13px;
    letter-spacing: .25px;
    padding: 15px 40px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .4s cubic-bezier(0, 0.55, 0.45, 1);

    &:hover{
        color: $white;
    }

    &--black{
        background-color: $black;
        border: 1.5px solid $black;
        color: $white;

        &:hover{
            background-color: transparent;
            color: $black;
        }
    }

    &--black-transparent{
        border: 1.5px solid $black;
        color: $black;
        padding: 10px 40px;        

        &:hover{
            background-color: $black;
            color: $white;
        }
    }

    &--line-before{
        padding-left: 80px;
        position: relative;

        &:before{
            content: '';
            background-color: $white;
            position: absolute;
            width: 25px;
            height: .5px;
            top: 28px;
            left: 35px;
            transition: all .4s cubic-bezier(0, 0.55, 0.45, 1);
        }

        &:after{
            content: '';
            background-color: $black;
            position: absolute;
            width: 0px;
            height: .75px;
            top: 28px;
            left: 35px;
            transition: all .4s cubic-bezier(0, 0.55, 0.45, 1);            
        }

        &:hover{
            &:after{
                width: 25px;
            }
        }
    }

    &--line-after{
        color: $black;
        text-decoration: underline;
        text-underline-offset: 7px;
        transition: all .4s cubic-bezier(0, 0.55, 0.45, 1);            

        &:hover{
            color: $black;
            text-decoration-color: $orange;
        }
    }
}