/**
 * Front
**/

/**
 * Elevator pitch
**/
.elevator-pitch{
    h2{
        max-width: 1085px;
        font-size: 31px;

        @include media-breakpoint-down(sm){
            font-size: 22px;
            line-height: 29px;            
        }

        a{
            color: $blue;
            text-decoration-thickness: 1.5px;
            text-underline-offset: 7px;
            text-decoration-color: $blue-light;
        }

        strong{
            font-weight: 400;
        }        
    }
}

/**
 * Services
**/
.image-wrapper{
    img{
        width: 100%;
    }
}

.featured-service{
    max-width: 520px;
    margin: 0 auto;    

    h3{
        font-size: 38px;
        line-height: 48px;
        letter-spacing: 0.8px;
        position: relative;

        &:before{
            content:'';
            position: absolute;
            background-color: #979797;
            width: 150px;
            height: 1px;
            top: -50px;
            left: calc(50% - 75px);
        }
    }

    a.btn{
        display: inline-block;
        margin-top: 20px;
    }

    a:not(.btn){
        color: $black;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;        
        transition: all .3s ease;

        &:hover{
            color: $orange;
        }
    }
}

/**
 * News
 **/
.news{
    h3{
        margin-bottom: 30px;
    }

    .btn{
        margin-top: 35px;
    }
}