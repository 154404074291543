/**
 * Global variables
 **/

$image_path: 'images/';


/**
 * Fonts
**/
@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.cdnfonts.com/css/butler?styles=20999,20998,20995');


$serif: 'Butler', serif;
$sans-serif: 'Arsenal', sans-serif;


/**
 * Colors
 **/

$primary-color: #FFFFFF;
$secondary-color: #FFFFFF;

$blue: #0061A8;
$blue-light: #E5EFF6;
$orange: #E8550D;
$orange-light: #FCEEE6;

$white: #FFFFFF;
$gray-light: #F2F2F2;
$gray: #3B3B3B;
$black: #181818;