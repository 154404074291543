/**
 * Footer
**/
footer{
    border-top: 1px solid rgba($black, .05);	

    h5{
        margin-bottom: 25px;

        @include media-breakpoint-down(xs){
            margin-top: 25px;
        }
    }

    ul{
        list-style-type: none;
        padding-left: 0;
        margin-right: 0;

        li{
            margin-bottom: 3px;
        }

        li a{
            color: $black;
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
            transition: all .3s cubic-bezier(0, 0.55, 0.45, 1);

            &:hover{
                color: $orange;
            }
        }
    }

    p{
        font-size: 14px;
    }

    .contact-info{
        a{
            color: $black;
            text-decoration: none;
            transition: all .3s cubic-bezier(0, 0.55, 0.45, 1);

            &:hover{
                color: $orange;
            }
        }
    }

    .social-media{
        img{
            margin-right: 20px;
        }

        a{
            text-decoration: none;
        }
    }
}

.footer-final{
    margin-bottom: 25px;

    a{
        color: $gray;
        font-size: 13px;
        text-decoration: none;
    }
}

.page-template-template-front{
    footer{
        border-top: 0;
    }
}

@include media-breakpoint-up(md){
    .developed-by{
        float: right;
    }
}